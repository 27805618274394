.no-style-input {
    width: fit-content;
    background: none;
    outline: none;
    border: none;
    position: relative;
    display: inline-flex;
    box-shadow: none;
    max-width: rem(100px);
    &-small {
        max-width: rem(100px);
    }
    &:disabled {
        color: $black;
    }
}
